import './App.scss';
import Homepage from './views/Homepage';
import MyRoutes from './MyRoutes';
import { BrowserRouter } from "react-router-dom";
function App() {
  return (<div className="App">
    <BrowserRouter basename={window.location.hostname === 'hrintegration.globalbit.gucci' ? '/competitorstool/' : ''}>
      <MyRoutes />
    </BrowserRouter>
  </div>);
}

export default App;