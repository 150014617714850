import { Component } from "react";
import axios from 'axios';
var BASE_URL = getApiUrl()
//get and automatic refresh token
const getToken = () => {
    if (localStorage.getItem('okta-token-storage') && JSON.parse(localStorage.getItem('okta-token-storage')).accessToken && JSON.parse(localStorage.getItem('okta-token-storage')).accessToken.accessToken)
        return JSON.parse(localStorage.getItem('okta-token-storage')).accessToken.accessToken
    else
        return ''
}
//dynamic url managing
function getApiUrl() {
    //prod
    if (window.location.hostname === 'competitorstool.globaltech.gucci') {
        return "https://competitorstool-api.globaltech.gucci"
    }
    //stag
    else {
        return "https://competitorstool-apidev.globaltech.gucci"
    }
}
export default class Service extends Component {
    //get user
    async getUser(id) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();

            axios.get(BASE_URL + '/User', {

            }).then((response) => {
                resolve(response.data)
            }).catch(function (error) {
                console.log(error)
                reject(error)
            });
        })
    }
    //test
    async test() {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();

            axios.get(BASE_URL + '/Sales/Error', {

            }).then((response) => {
                resolve(response.data)
            }).catch(function (error) {
                console.log(error)
                reject(error)
            });
        })
    }
    // check Calendar Type
    async checkCalendarType(loc, brand_id, obj) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();

            axios.post(BASE_URL + '/Sales/Location/' + loc + '/' + brand_id + '/calendar', obj, {

            }).then((response) => {
                resolve(response.data)
            }).catch(function (error) {
                reject(error)
            });
        })
    }
    //get all locations
    async getAllLocations(id) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();

            axios.get(BASE_URL + '/Location/All', {

            }).then((response) => {
                resolve(response.data)
            }).catch(function (error) {
                reject(error)
            });
        })
    }
    //get Competitors
    async getCompetitorsByLocation(id) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();

            axios.get(BASE_URL + '/Location/' + id + '/Competitors', {

            }).then((response) => {
                resolve(response.data)
            }).catch(function (error) {
                reject(error)
            });
        })
    }
    //get sales by Location
    async getSalesByLocation(id, obj) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();

            axios.post(BASE_URL + '/Sales/Location/' + id, obj, {

            }).then((response) => {
                resolve(response.data)
            }).catch(function (error) {
                reject(error)
            });
        })
    }
    //search Location
    async searchLocation(text) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();

            axios.get(BASE_URL + '/Location/Search?searchText=' + text, {

            }).then((response) => {
                resolve(response.data)
            }).catch(function (error) {
                reject(error)
            });
        })
    }
    //get brands by Location
    async getBrandsByLocation(id) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();

            axios.get(BASE_URL + '/Location/' + id + '/Competitors', {

            }).then((response) => {
                resolve(response.data)
            }).catch(function (error) {
                reject(error)
            });
        })
    }
    //insert Sales
    async insertSales(id, obj) {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();

            axios.post(BASE_URL + '/Sales/Location/' + id + '/Insert', obj, {

            }).then((response) => {
                resolve(response.data)
            }).catch(function (error) {
                reject(error)
            });
        })
    }
    async uploadFile(file) {
        const headers = {
            'Content-Type': 'multipart/form-data'
        }
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.post(BASE_URL + '/Sales/Upload', file, headers).then(async (response) => {
                resolve(response.data)
            }).catch(function (error) {
                console.log(error)
                reject(error)
            });
        })
    }
    async exportData(obj, jda) {

        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.post(BASE_URL + '/Sales/Export/' + jda, obj, { responseType: 'blob' }).then(async (response) => {
                let date = new Date().toLocaleDateString() + 'T' + new Date().toLocaleTimeString()
                const type = response.headers['content-type']
                const filename = response.headers['content-disposition']
                const blob = new Blob([response.data], { type: type })
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = "export_" + date + ".xlsx"
                link.click()
                resolve(response.data)
            }).catch(function (error) {
                console.log(error)
                reject(error)
            });
        })
    }
    async downloadSampleFile() {

        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.get(BASE_URL + '/Sales/Upload/Sample', { responseType: 'blob' }).then(async (response) => {
                const type = response.headers['content-type']
                const filename = response.headers['content-disposition']
                const blob = new Blob([response.data], { type: type })
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = "sample_file.xlsx"
                link.click()
                resolve(response.data)
            }).catch(function (error) {
                console.log(error)
                reject(error)
            });
        })
    }
    async getCurrencies() {

        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.get(BASE_URL + '/Sales/Currency').then(async (response) => {
                resolve(response.data)
            }).catch(function (error) {
                console.log(error)
                reject(error)
            });
        })
    }
    async deleteSales(location_id, obj) {
        console.log(obj)
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = await getToken();
            axios.delete(BASE_URL + '/Sales/Location/' + location_id + '/Delete', { data: obj }).then(async (response) => {
                resolve(response.data)
            }).catch(function (error) {
                console.log(error)
                reject(error)
            });
        })
    }

}