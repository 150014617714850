import { useEffect, useState } from "react"
import Service from "../services/Service";
import { brand_name_label, comments_label, metrics_label, ratio_label, tm_vs_lm_label, tm_label, vs_ly_label, lm_label, customStylesModal } from "../global";
import { toast } from "react-toastify";
import Modal from 'react-modal';
import 'react-toastify/dist/ReactToastify.css';
import { Slide } from 'react-toastify';
export default function TableComponent(props) {
    const [gucciStoreSales, setGucciStoreSales] = useState(null)
    const [competitorsSales, setCompetitorsSales] = useState([])
    const [loading, setLoading] = useState(true)
    const [months, setMonths] = useState([])
    const [confirmDialog, setConfirmDialog] = useState(false)
    const [tmpCompetitor, setTmpCompetitor] = useState(null)
    const [tmpSales, setTmpSales] = useState(null)
    let service = new Service()

    useEffect(() => {

        loadSales()
    }, [props.location, props.selectedBrands, props.date])
    const loadSales = () => {
        setLoading(true)
        let brandsIds = []
        let obj = {}
        if (props.selectedBrands && props.selectedBrands.length > 0) {
            brandsIds = props.selectedBrands.map((b) => b.value)
            obj['brand_ids'] = brandsIds
        }
        if (props.date) {
            obj['year'] = props.date.getFullYear()
            obj['month'] = props.date.getMonth() + 1
        }
        if (props.location && props.location.value)
            service.getSalesByLocation(props.location.value, obj).then((s) => {
                //  setGucciStoreSales(s.gucci_store_sales)
                // if (s.gucci_store_sales.store && s.gucci_store_sales.store.location_code && s.gucci_store_sales.store.location_description) {
                //     props.setLocation(s.gucci_store_sales.store.location_code)
                //     props.setLocationDescription(s.gucci_store_sales.store.location_description)
                // }
                //   props.setLocation(s.gucci_store_sales.store.location_code)
                setCompetitorsSales(s.competitors_sales)
                setMonths(s.months)
                setTimeout(() => {
                    setLoading(false)
                }, 1000);
            })

    }

    let monthThead = ''
    for (let i = 0; i < new Date().getMonth() + 1; i++)
        monthThead = months.map((m) => <><th>
            <div>{m.month}</div>
            <div>{m.year}</div>
        </th><th>{vs_ly_label}</th></>
        )

    const deleteSales = () => {
        let obj = {
            'brand_id': tmpCompetitor.competitor.id,
            'year': tmpSales.year,
            'month': new Date(Date.parse(tmpSales.month + " 1, 2012")).getMonth() + 1
        }
        service.deleteSales(props.location.value, obj).then((data) => {
            setConfirmDialog(false)
            toast.success(data.message)
            setTimeout(() => {

                loadSales()
            }, 200);
        })
    }
    const myFunction = () => {
        document.getElementById("myDropdown").classList.toggle("show");
    }
    const deleteSalesConfirm = (competitor, sales) => {
        setTmpCompetitor(competitor)
        setTmpSales(sales)
        setConfirmDialog(true)
    }
    const getMonthsListValues1 = (array, store) => {
        if (array && array.sales)
            return array.sales.map((m) => <>
                <td>
                    <div className={m.status == 'Not present' ? "mid book_ita" : "mid"} > {m.status === 'Not present' ? m.status : (m.sales ? m.sales : '')} {m.status !== 'Not present' && m.currency ? m.currency : ''}
                        <div className="sales_reset" onClick={() => deleteSalesConfirm(array, m)}>
                            {m.status === 'Not present' || m.sales ? <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                            </svg> : ''}
                            {/*} <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                            </svg>*/}

                            {/*   <div className='dropdown'>
                                <div className="header_user  " onClick={() => myFunction()} >
                                    <div className="user_name dropbtn"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                        <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                                    </svg></div>
                                    <div id="myDropdown" class="dropdown-content-header">
                                        <div className="flex drop" >
                                            <div className="left_menu_text" Style={'align-self: center;'} >    Delete</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

*/}



                        </div>
                    </div>
                    <div className="book_ita cal_type"> {m.sales && m.is_solar_calendar ? 'solar calendar' : (m.sales && !m.is_solar_calendar ? 'merch calendar' : '')}</div>
                </td>
                <td>
                    <div className="mid" Style={'color:' + (parseFloat(m.vs_last_year) && parseFloat(m.vs_last_year) > 0 ? 'green' : 'red')}>{m.vs_last_year ? m.vs_last_year : ''}</div>
                </td>
            </>)
        else return ''
    }
    const getMonthsListValues2 = (array, store) => {
        if (array && array.sales)
            return array.sales.map((m) => <>
                <td>
                    <div className="mid" Style={'color:' + (parseFloat(m.vs_last_month) && parseFloat(m.vs_last_month) > 0 ? 'green' : 'red')}>{m.vs_last_month ? m.vs_last_month : ''}</div>
                </td>
                <td>
                    <div className="mid"></div>
                </td>
            </>)
        else return ''
    }
    const getMonthsListValues3 = (array, store) => {
        if (array && array.sales)
            return array.sales.map((m) => <>
                <td>
                    {store === 'competitorStore' ? <div className={m.status == 'Not comparable' ? "mid book_ita" : "mid"}>{m.status === 'Not comparable' ? m.status : (m.ratio ? m.ratio : '')}</div> : ''}
                </td>
                <td>
                    {store === 'competitorStore' ? <div className="mid"></div> : ''}
                </td>
            </>)
        else return ''
    }

    let competitorsSalesData = ''
    if (competitorsSales && competitorsSales.length > 0)
        competitorsSalesData = competitorsSales.map((c) => <>
            <tr>
                <td className="td-first_column" rowSpan={3}>{c.competitor && c.competitor.brand ? c.competitor.brand : ''}</td>
                <td class="td-second_column">
                    <div className="mid">{tm_label}</div>
                </td>
                {getMonthsListValues1(c, 'competitorStore')}

                {/*<td><div className="mid">{c.sales_lm ?? ''}</div> </td>*/}
                {props.date ? <td className=" td-comment" rowSpan={3}> {c.sales[1] ? c.sales[1].comments : ''}</td> : ''}
            </tr>
            <tr>
                <td class="td-second_column">
                    <div className="mid">{tm_vs_lm_label}</div>
                </td>
                {getMonthsListValues2(c, 'competitorStore')}
                {/*  <td><div className="mid"></div></td>*/}
            </tr>
            <tr>
                <td class="td-second_column">
                    <div className="mid">{ratio_label}</div>
                </td>
                {getMonthsListValues3(c, 'competitorStore')}
                {/* <td><div className="mid"></div></td>*/}
            </tr>
        </>
        )
    const download = () => {
        service.downloadSampleFile().catch(() => {
            toast.error('Something went wrong!', {
                background: "#00FF00 !important",
                transition: Slide,
                toastId: 'export_error1'
            })
        })
    }

    return (<div>
        {!loading ? <div className="table_container " Style={'height:calc(100vh - ' + 225 + 'px); min-height:150px'}>
            <div className="responsive-table">
                <div className="download_text" onClick={download}>Download sample file</div>

            </div>
            <table class="responsive-table mt0">
                <thead>
                    <tr class="table-header" Style={'width: fit-content'}>
                        <th class="th-first_column" >{brand_name_label}</th>
                        <th class="th-second_column">{metrics_label}</th>
                        {monthThead}
                        {/*<th>{lm_label}</th>*/}
                        {props.date ? <th>{comments_label}</th> : ''}
                    </tr>

                </thead>
                <tbody>
                    {/*gucciSalesData*/}
                    {competitorsSalesData}
                </tbody>
            </table>
            <Modal
                ariaHideApp={false}
                isOpen={confirmDialog}
                onRequestClose={() => setConfirmDialog(false)}
                style={customStylesModal}
            >
                <div className='modal_filters_container'>
                    <div>
                        <div className='filter-label-cont' Style={'text-align:center'}> Are you sure</div>

                        <svg onClick={() => setConfirmDialog(false)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor " class="bi bi-x close-button" viewBox="0 0 16 16">
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                        </svg>
                    </div>
                    <hr className='separator' />
                    <div className='label_text center'>
                        You are about to delete the data for {tmpSales && tmpSales.month ? tmpSales.month : ''} {tmpSales && tmpSales.year ? tmpSales.year : ''}</div>
                    <div className="label_text_bold center">Do you want to proceed?</div>
                    <div className='flex_center mt-10' Style={'width:100%'}>
                        <button className='cancel-button' onClick={() => setConfirmDialog(false)}>Cancel</button>
                        <button className='submit-button' onClick={() => deleteSales()}>Delete</button>
                    </div>
                </div>
            </Modal >
        </div>
            : <div className="loader-cont">
                <div className="loader1"></div>
            </div>}
    </div>)
}