import { LoginCallback } from "@okta/okta-react";
import Homepage from "./views/Homepage";

function LoginManager() {

    return (
        localStorage.getItem('okta-token-storage') && JSON.parse(localStorage.getItem('okta-token-storage')).accessToken && JSON.parse(localStorage.getItem('okta-token-storage')).accessToken.accessToken ?
            <Homepage />
            : <LoginCallback />
    );

}

export default LoginManager;